











































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { formatDate } from '@/helpers/dateTimeHelper';
import meQuery from '@/graphql/me/Me.query.gql';
import updateMeDetails from '@/graphql/me/account/UpdateUserDetails.mutation.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import FormInput from '@/components/partials/elements/FormInput.vue';
import CollapseTransition from '@/components/transitions/CollapseTransition.vue';
import AccountChangePasswordForm from '@/components/partials/AccountChangePasswordForm.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheArrowDownIcon from '@/components/icons/TheArrowDownIcon.vue';

@Component({
  components: {
    TheArrowDownIcon,
    TheArrowHorizIcon,
    AccountChangePasswordForm,
    CollapseTransition,
    BackHeader,
    FormInput,
  },
  metaInfo: {
    title: 'Account details',
  },
  apollo: {
    me: {
      query: meQuery,
      result(result: any) {
        const user = result?.data?.me;
        if (!user) {
          return;
        }

        this.userData = user;

        if (this.userData.address) {
          this.userDetailsForm.address_line_1 = this.userData.address.address_line_1;
          this.userDetailsForm.address_line_2 = this.userData.address.address_line_2;
          this.userDetailsForm.city = this.userData.address.city;
          this.userDetailsForm.postal_code = this.userData.address.postal_code;
          this.userDetailsForm.state = this.userData.address.state;
          this.userDetailsForm.country = this.userData.address.country;
        }
        this.userDetailsForm.contact_email_2 = this.userData.contact_email2;
        this.userDetailsForm.phone = this.userData.phone;
        this.userDetailsForm.other_phone = this.userData.other_phone;

        if (this.userData.other_phone) {
          this.addOtherPhone = true;
        }
        if (this.userData.address && this.userData.address.address_line_2) {
          this.addAddressLine2 = true;
        }
      },
    },
  },
})
export default class MyMembership extends Vue {
  userData: any = {};
  userDetailsForm: any = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    country: '',
    postal_code: '',
    state: '',
    contact_email_2: '',
    phone: '',
    other_phone: '',
  };
  addAddressLine2 = false;
  addOtherPhone = false;

  sendingDetailsForm = false;

  validationErrors: any = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    country: '',
    postal_code: '',
    state: '',
    contact_email_2: '',
    phone: '',
    other_phone: '',
  };

  isShowChangePasswordFormMobModal = false;
  isChangePasswordFormCollapsed = false;

  get membershipExpiryDate() {
    return formatDate(this.userData.membership.expiry_date);
  }

  get memberSinceDate() {
    return formatDate(this.userData.membership.member_since);
  }

  get memberCardExpiryDate() {
    return formatDate(this.userData.membership.card_expiry);
  }

  created() {
    this.$root.$on('closeModalProfile', this.hideChangePasswordFormMobModal);
  }

  resetValidationErrors(key: string) {
    if (this.validationErrors[key] !== undefined) {
      this.validationErrors[key] = '';
    }
  }

  toggleChangePasswordForm() {
    this.isChangePasswordFormCollapsed = !this.isChangePasswordFormCollapsed;
  }

  afterShowingChangePasswordForm() {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
  }

  showChangePasswordFormMobModal() {
    this.isShowChangePasswordFormMobModal = true;
    this.$store.dispatch('disableScroll');
  }

  hideChangePasswordFormMobModal() {
    this.isShowChangePasswordFormMobModal = false;
    this.$store.dispatch('enableScroll');
  }

  changeAccountDetails() {
    if (this.sendingDetailsForm) {
      return;
    }
    this.sendingDetailsForm = true;
    this.$apollo
      .mutate({
        mutation: updateMeDetails,
        variables: {
          address_line_1: this.userDetailsForm.address_line_1,
          address_line_2: this.userDetailsForm.address_line_2,
          city: this.userDetailsForm.city,
          country: this.userDetailsForm.country,
          postal_code: this.userDetailsForm.postal_code,
          state: this.userDetailsForm.state,
          contact_email2: this.userDetailsForm.contact_email_2,
          phone: this.userDetailsForm.phone,
          other_phone: this.userDetailsForm.other_phone,
        },
      })
      .then(() => {
        this.sendingDetailsForm = false;
        this.$toast.success('Your data were saved successfully.');
      })
      .catch((err: any) => {
        const { graphQLErrors } = err;
        if (graphQLErrors[0].extensions?.validation) {
          const errors = graphQLErrors[0].extensions.validation;
          for (let field in errors) {
            const fieldName = field.replace(/(addressInput|emailPhoneInput)\./, '');
            if (this.validationErrors[fieldName] !== undefined) {
              this.validationErrors[fieldName] = errors[field][0];
            }
          }
        }
        this.sendingDetailsForm = false;
        this.$toast.error('Failed to save your data.');
      });
  }
}
